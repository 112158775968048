<template>
	<div>
		<!-- 图片 -->
		<div>
			<img class="in_02 w100 display-block" src="@/assets/img/in_02.png" alt="">
		</div>
		
		<div class="center flex-column">
			<div class="w1200">
				<div class="h100px align-items-center fz40 co-333333">攻略</div>
			</div>
			
			<!-- 列表 -->
			<div class="w100 bg-F7F7F7 center flex-column" style="height: 90vh;">
				<div  class="flex" style="overflow:auto;height: 100vh;">
				<div class="w1200">
					<div class="justify-content-center-between paddt20 paddb40">
						<div class="align-items-center">
							<span class="fz20 co-333333">热门搜索</span>
							<div class="title-item center cure-pointer fz14" v-for="(item, i) in titleList" :key="i" 
							:class="[titleIndex == item.id? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl32' : '']" @click="titleChoose(i)">{{item.name}}</div>
						</div>
					</div>
							
					<Competition :list="list"></Competition>
					<div class="display-flex w1200 flex-column" v-if="isSearch">
						<div class="" v-if="picture.length > 0">
								<div class="fz30 co-333333 font-blod marb30">文章</div>
								<Inspiration :list="picture"></Inspiration>
						</div>
						<div class="" v-if="resource.length > 0">		
								<div class="fz30 co-333333 font-blod marb30">素材</div>
								<Material :list="resource"></Material>
						</div>
						<div class="" v-if="course.length > 0">
								<div class="fz30 co-333333 font-blod marb30">课程</div>
								<ClassSelect :list="course"></ClassSelect>
						</div>
						<div class="" v-if="competition.length > 0">
								<div class="fz30 co-333333 font-blod marb30">竞赛</div>
								<Competition :list='competition'></Competition>
						</div>
						<div class="" v-if="teacher.length > 0">
								<div class="fz30 co-333333 font-blod marb30">名师</div>
							
								<teacherlist :list='teacher'></teacherlist>
						</div>
						<div class="" v-if="school.length > 0">
								<div class="fz30 co-333333 font-blod marb30">院校</div>
								<Competition :list="school" type="2"></Competition>
						</div>
						<div class="" v-if="agency.length > 0">
								<div class="fz30 co-333333 font-blod marb30">机构</div>
								<SincerityList :list="agency"></SincerityList>
						</div>
					</div>
					<loadMore :state="state" loading_icon></loadMore>
					<!-- <div class="center paddt54">
						<el-pagination   class="center " background :current-page="page" @current-change="handleCurrentChange"
						    layout="prev, pager, next, jumper" :total="dictTotal" :page-size="size">
						</el-pagination>
					</div> -->
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Strategy",
		data() {
			return {
				titleList: [], // 筛选标题
				titleIndex: '', // 筛选标题选中
				pull: false, // 是否下拉
				selectList: ['综合排序', '最新', '最热'], // 下拉列表
				seletIndex: 0, // 下拉选中
				list:[],
				page: 0,
				state: 0,
				loading: false,
				dictTotal: 0,
				size: 16,
				content: '', //搜索关键词
				picture: [], //研图社
				resource: [], //素材
				course: [], //课程
				competition: [], //竞赛
				teacher: [], //名师
				school: [], //学校
				agency: [], //机构
				isSearch:false
			}
		},
		mounted() {
		
			//热门搜索
			this.$req({
				method: 'get',
				url: '/api/tags/recommend',
				success: res => {
		
					this.titleList = res
				},
				fail: error => {}
			});
			this.getlist();
		},
		methods: {
			getlist(){
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 50;
				this.$req({
					method: 'get',
					url: '/api/recommend/found',
					success: res => {
						
						let list = res;
						this.list = list;
						if (list.length == size) {
							this.state = 1;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.list.length == 0) {
							this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
					}
				});
			},
			// 筛选标题选中
			titleChoose(e) {
				this.titleIndex = this.titleList[e].id;	
				this.toSearch(this.titleList[e].name)
			},
			handleCurrentChange(val) {
			    this.getList();
			    let w = document.documentElement.clientWidth || document.body.clientWidth;
			    document.documentElement.scrollTop = document.body.scrollTop = w * 0.4;
			},
			toSearch(e) {
				this.state = 1;
				this.$req({
					method: 'get',
					url: '/api/search',
					data: {
						search: e
					},
					success: res => {
						this.picture = res.picture;
						this.resource = res.resource;
						this.course = res.course;
						this.competition = res.competition;
						this.teacher = res.teacher;
						this.school = res.school;
						this.agency = res.agency;
						this.isSearch = true;
						this.state = 0;
					},
					fail: error => {
			
					}
				});
				this.state = 0;
			}
		}
	}
</script>

<style lang="less" scoped>
	.in_02 {
		height: 200px;
	}
	
	/* 筛选标题 */
	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
	}
	
	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;
		
		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}
		
		.rotate {
			transform: rotate(180deg);
		}
		
		.triangle {
			width: 27px;
			height: 15px;
			top: 28px;
			right: 0;
			z-index: 100;
		}
		
		.pull-box {
			width: 140px;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			top: 43px;
			border-radius: 8px;
			left: 0;
			overflow: hidden;
			transition: all 0.3s;
			
			.check_icon {
				width: 15px;
				height: 15px;
			}
		}
	}
	
	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999!important;
	}
	
	.btn-prev, .btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}
	
	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
